import { lazy } from "react";

export const publicRoutes = [
   {
      path: "/",
      exact: true,
      component: lazy(() => import("@iso/pages/Login")),
   },
   {
      path: "/feedback-bathroom",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Bathroom")),
   },
   {
      path: "/feedback-bathroom/:storeCode",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Bathroom")),
   },
   {
      path: "/feedback-coffee",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Coffee")),
   },
   {
      path: "/feedback-coffee/:storeCode",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Coffee")),
   },
   {
      path: "/feedback-coffee-4ps",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Coffee4PS")),
   },
   {
      path: "/feedback-coffee-4ps/:storeCode",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Coffee4PS")),
   },
   {
      path: "/feedback-delivery/:fbCode",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Delivery")),
   },
   {
      path: "/feedback-delivery/ippudo/:storeCode/:orderNo/:currentDate",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Ippudo")),
   },
   {
      path: "/feedback-general/:channel/:storeId/:orderId/:feToken/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Auth/BYOD")),
   },
   {
      path: "/feedback-general/:channel/:storeId/:orderId/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Auth/BYOD")),
   },
   {
      path: "/feedback-general/:channel/:storeId",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/General")),
   },
   {
      path: "/feedback-general-byod/:channel/:storeId/:orderId/:feToken/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/Auth/BYOD")),
   },
   {
      path: "/feedback-byod/:orderId/:storeCode/:tableNo/:tableName/:orderSequence/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/BYOD")),
   },
   {
      path: "/feedback-tto/:orderId/:storeCode/:tableNo/:tableName/:orderSequence/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/TTO")),
   },
   {
      path: "/feedback-ippudo/:orderId/:storeCode/:tableNo/:tableName/:orderSequence/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/IppudoDineIn")),
   },
   {
      path: "/feedback-ippudo-byod/:orderId/:storeCode/:tableNo/:tableName/:orderSequence/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/IppudoDineInBYOD")),
   },
   {
      path: "/feedback-ippudo-tto/:orderId/:storeCode/:tableNo/:tableName/:orderSequence/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/IppudoDineInTTO")),
   },
   {
      path: "/feedback-dine-in/:orderId/:brand/:storeCode/:tableNo/:tableName/:orderSequence/:openDate/:lang",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/FeedbackDineIn")),
   },
   {
      path: "/s",
      exact: true,
      component: lazy(() => import("@iso/pages/Feedback/FeedbackQR")),
   },
];
